<div class="modal">
  <div class="alert-dialog">
    <div class="alert-dialog__content flex">
      <div class="alert-dialog__main">
        <div class="alert-dialog__icon">
          <svg class="svg-icon icon-alerts">
            <use xlink:href="#icon-alerts"></use>
          </svg>
        </div>
        <div class="alert-dialog__main-body">
          <h3>{{ content?.heading }}</h3>
          <p>{{ content?.body }}</p>
        </div>
      </div>
      <div class="alert-dialog__action mt-6">
        <dgx-components-button
          type="submit"
          variant="secondary"
          iconName="arrow-right"
          (click)="onClick()"
          data-automation="'ConfirmAD'"
        >
          {{ content?.cta }}
        </dgx-components-button>
      </div>
    </div>
  </div>
</div>
