<h2>Confirm if you’d like to book:</h2>

<div class="notice">
  <div class="xs-12 flex mb-4">
    <svg class="svg-icon icon-calendar">
      <use xlink:href="#icon-calendar"></use>
    </svg>
    <div class="flex-column">
      <h5 class="typog-body-bold xs--blockmr-4 mb-none">Engineer visit date</h5>
      <p class="xs--block">{{ day?.date | date : 'd MMMM YYYY' }}</p>
    </div>
  </div>
  <div class="xs-12 flex">
    <svg class="svg-icon icon-clock">
      <use xlink:href="#icon-clock"></use>
    </svg>
    <div class="flex-column">
      <h5 class="typog-body-bold xs--block mr-4 mb-none">Time slot</h5>
      <p class="xs--block mr-3">
        An engineer will be in touch to confirm the arrival time.
      </p>
    </div>
  </div>
</div>

<dgx-components-button
  type="submit"
  variant="primary"
  iconName="arrow-right"
  data-automation="'ConfirmAD'"
  (click)="selectSlot()"
>
  Book this date
</dgx-components-button>
