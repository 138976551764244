<dg-svg></dg-svg>
<!-- <dgx-ui-wl-header
  clientLogoPath="assets/images/ao-logo.svg"
  [navigationLinks]="headerNavigationLinks$ | async"
  [isLoggedIn]="true"
  (logout)="logout()"
></dgx-ui-wl-header> -->

<dg-loader
  *ngIf="(loadingState$ | async)?.isLoading"
  [message]="(loadingState$ | async)?.message"
></dg-loader>
<main>
  <router-outlet></router-outlet>
</main>

<!-- <dgx-ui-wl-ao-footer
  [protectLinks]="footerProtectLinks"
  [legalLinks]="footerLegalLinks"
  [usefulLinks]="footerUsefulLinks"
></dgx-ui-wl-ao-footer> -->
