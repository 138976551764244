<div class="grid-row booking">
  <div class="xs-12 flex booking-heading">
    <svg class="svg-icon icon-calendar">
      <use xlink:href="#icon-calendar"></use>
    </svg>
    <div class="flex-column">
      <h5 class="typog-body-bold xs--block md--inline mr-3 mb-none">
        Earliest repair date
      </h5>
      <p class="link xs--block md--inline" (click)="selectFirstDate?.emit()">
        {{ firstDate?.date | date : 'd MMMM YYYY' }}
      </p>
    </div>
  </div>
  <div class="xs-12 booking-body">
    <p *ngIf="slotType === slotTypes.SPECIFIC">
      Pick a day on the calendar and then select a time. We’ve updated this
      calendar with all the available slots.
    </p>
    <p *ngIf="slotType === slotTypes.ALLDAY">
      Pick a day, and an engineer will contact you beforehand with their arrival
      time. We’ve updated this calendar with all the available slots.
    </p>
  </div>
</div>
