import {
  CardPaymentRedirectRequest,
  CardPaymentRedirectResponse,
  ServiceOption,
} from '@domgen/dgx-components';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, of, zip } from 'rxjs';
import {
  map,
  switchMap,
  flatMap,
  catchError,
  first,
  withLatestFrom,
  repeat,
} from 'rxjs/operators';

/* NgRx */
import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as fromClaims from './';
import * as claimActions from './claim.actions';
import { ApiService } from '../services/api.service';
import { SpecialRoutingService } from '../services/special-routing.service';
import { ClaimEffectHelperService } from '../services/claim-effect-helper.service';
import { Api } from '@domgen/dgx-components';
import { ErrorMessages } from '@domgen/dgx-components';
import {
  ServiceOptionTypes,
  Claim,
  CustomerDetails,
} from '@domgen/dgx-components';
import { PayloadBooking } from './claim.actions';
import { BookingMapService } from '@domgen/dgx-components';
import { Origin } from '@domgen/dgx-components';
import { ClaimFacade } from './claim.facade';

@Injectable()
export class BookingEffects {
  constructor(
    private claimService: ApiService,
    private actions$: Actions,
    private routing: SpecialRoutingService,
    private store: Store<fromClaims.State>,
    private helper: ClaimEffectHelperService,
    private maps: BookingMapService,
    private readonly claimFacade: ClaimFacade
  ) {}

  selectServiceOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.SelectServiceOption),
      switchMap((action) => {
        return zip(
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          of(action)
        );
      }) as OperatorFunction<
        unknown,
        [Claim, { type: string; payload: ServiceOption }]
      >,
      switchMap(
        ([claim, action]: [
          Claim,
          { type: string; payload: ServiceOption }
        ]) => {
          const serviceOption = action.payload as ServiceOption;

          switch (serviceOption.ServiceOptionType) {
            case ServiceOptionTypes.SelfSend:
              return of(claimActions.SelfSendBooking());
            case ServiceOptionTypes.PayClaim:
              return of(
                claimActions.PayAndClaim({
                  payload: {
                    serviceOptionID: serviceOption.ServiceOptionID,
                  },
                })
              );
            case ServiceOptionTypes.Manual:
              return of(
                claimActions.ManualBooking({
                  payload: {
                    serviceOptionID: serviceOption.ServiceOptionID,
                  },
                })
              );
            case ServiceOptionTypes.Home:
              return of(claimActions.EngineerBooking());
            case ServiceOptionTypes.DropOff:
              return of(
                claimActions.DropOffBooking({
                  payload: {
                    dropOffOrigin: {
                      CustomersHouseStreetName: claim.customer
                        ?.CustomersHouseStreetName as string,
                      CustomersTownCity: claim.customer
                        ?.CustomersTownCity as string,
                      CustomersLocalArea: claim.customer
                        ?.CustomersLocalArea as string,
                      CustomersPostCode: claim.customer
                        ?.CustomersPostCode as string,
                    },
                  },
                })
              );
            case ServiceOptionTypes.Collection:
              return of(claimActions.CollectionBooking());
            default:
              return of(
                claimActions.Error({
                  payload: new Error(
                    ErrorMessages.NoRecognisedServiceOptionTypes
                  ),
                })
              );
          }
        }
      )
    )
  );

  getServiceOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.GetServiceOptions),
      switchMap((action) => {
        return zip(
          this.store.select(fromClaims.getActiveClaim).pipe(first()),
          of(action)
        );
      }) as OperatorFunction<
        unknown,
        [Claim, { type: string; payload: Api.GetServiceOptionRequest }]
      >,
      switchMap(
        ([claim, action]: [
          Claim,
          { type: string; payload: Api.GetServiceOptionRequest }
        ]) => {
          return this.claimService.getServiceOptions(action.payload).pipe(
            switchMap((response: Api.GetServiceOptionResponse) => {
              if (!response?.ServiceOptions?.length) {
                return of(
                  claimActions.Error({
                    payload: new Error(
                      ErrorMessages.NoRecognisedServiceOptionTypes
                    ),
                  })
                );
              }

              const updatedResponse = {
                ...response,
                PaymentRequired: true,
                PaymentData: [{
                  Type: 'CALLOUT',
                  Label: 'Call Out Charge',
                  Amount: '100.00',
                }]
              }

              const actions: Action[] = [
                claimActions.LoadServiceOptions({
                  payload: updatedResponse,
                }),
              ];

              if (this.helper.canUpdateUserDetails(claim)) {
                const personalDetailsChanged =
                  this.helper.personalDetailsChanged(
                    claim?.customer as CustomerDetails,
                    claim?.reflect as Api.Reflect
                  );

                const addressDetailsChanged = this.helper.addressDetailsChanged(
                  claim?.customer as CustomerDetails,
                  claim?.reflect as Api.Reflect
                );

                if (personalDetailsChanged) {
                  actions.push(claimActions.UpdateUserPersonalDetails());
                }

                if (addressDetailsChanged) {
                  actions.push(claimActions.UpdateUserAddressDetails());
                }
              }

              return actions;
            })
          );
        }
      ),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  updateUserPersonalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.UpdateUserPersonalDetails),
      switchMap(
        () =>
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>
      ),
      switchMap((claim: Claim) => {
        return this.claimService
          .updateContactDetails({
            contact: this.helper.removeEmptyStringKeyValues({
              landlinePhoneNumber: claim.customer?.CustomersLandLine,
              mobilePhoneNumber: claim.customer?.CustomersMobile,
            }),
          })
          .pipe(
            map(() =>
              claimActions.UpdateUserPersonalDetailsSuccess({
                payload: {
                  successfull: true,
                  changed: true,
                },
              })
            )
          );
      }),
      catchError(() => {
        return of(
          claimActions.UpdateUserPersonalDetailsFailed({
            payload: {
              successfull: false,
              changed: true,
            },
          })
        );
      })
    )
  );

  updateUserAddressDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.UpdateUserAddressDetails),
      switchMap(
        () =>
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>
      ),
      switchMap((claim: Claim) => {
        return this.claimService
          .updateContactAdress({
            correspondenceAddress: {
              addressLine1: claim.customer?.CustomersHouseStreetName as string,
              addressLine2: claim.customer?.CustomersTownCity as string,
              addressLine3: claim.customer?.CustomersLocalArea as string,
              addressLine4: '',
              countryCode: 'GBR',
              postalCode: claim.customer?.CustomersPostCode as string,
            },
          })
          .pipe(
            map(() =>
              claimActions.UpdateUserAddressDetailsSuccess({
                payload: {
                  successfull: true,
                  changed: true,
                },
              })
            )
          );
      }),
      catchError(() => {
        return of(
          claimActions.UpdateUserAddressDetailsFailed({
            payload: {
              successfull: false,
              changed: true,
            },
          })
        );
      })
    )
  );

  loadingFunnel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.DropOffBooking,
        claimActions.ClaimActionTypes.SelfSendBooking,
        claimActions.ClaimActionTypes.PayAndClaim,
        claimActions.ClaimActionTypes.ManualBooking
      ),
      map(() => {
        return claimActions.Loading({ payload: '' });
      })
    )
  );

  loadingFunnelMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.EngineerBooking,
        claimActions.ClaimActionTypes.CollectionBooking,
        claimActions.ClaimActionTypes.GetBookingExtraSlots
      ),
      map(() => {
        return claimActions.Loading({
          payload:
            'This may take a moment – we’re loading the best slots available from the manufacturer’s repair system.',
        });
      })
    )
  );

  loadingExtraSlotsRequestMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.RetryPutServiceOption,
        claimActions.ClaimActionTypes.GetBookingFollowingWeekSlots
      ),
      map(() => {
        return claimActions.Loading({
          payload:
            'We’re seeing a very high demand for repair bookings, so your request may take a little longer than usual.',
        });
      })
    )
  );

  bookingAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.EngineerBooking,
        claimActions.ClaimActionTypes.CollectionBooking,
        claimActions.ClaimActionTypes.SelfSendBooking,
        claimActions.ClaimActionTypes.DropOffBooking,
        claimActions.ClaimActionTypes.RetryPutServiceOption,
        claimActions.ClaimActionTypes.GetBookingExtraSlots,
        claimActions.ClaimActionTypes.SilentGetBookingExtraSlots,
        claimActions.ClaimActionTypes.CancelSilentGetBookingExtraSlots,
        claimActions.ClaimActionTypes.GetBookingFollowingWeekSlots
      ),
      switchMap((action) => {
        return zip(
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          of(action)
        );
      }),
      switchMap(
        ([claim, action]: [
          Claim,
          { type: string; payload?: PayloadBooking }
        ]) => {
          if (
            action.type ===
            claimActions.ClaimActionTypes.CancelSilentGetBookingExtraSlots
          ) {
            return [];
          }

          return this.claimService
            .putServiceOption(this.helper.getPutServiceOptionPayload(claim))
            .pipe(
              flatMap((response: Api.PutServiceOptionResponse) => {
                const attemptCount: number = action?.payload?.attemptCount || 0;
                const manualReferral = this.helper.getManualReferralFallback(
                  claim?.serviceOptions?.ServiceOptions as ServiceOption[]
                );

                if (
                  this.helper.canRetryRequest(
                    attemptCount,
                    response.StatusCode
                  ) &&
                  response?.ExtraAvailability === true
                ) {
                  return of(
                    claimActions.RetryPutServiceOption({
                      payload: {
                        attemptCount: attemptCount + 1,
                      },
                    })
                  );
                }

                // if weekly response is empty and can request more
                if (this.helper.isWeekEmpty(response)) {
                  return of(
                    claimActions.GetBookingFollowingWeekSlots({
                      payload: response,
                    })
                  );
                }

                if (this.helper.dropOffBooking(claim)) {
                  return of(
                    claimActions.DropOffPutServiceOption({
                      payload: {
                        response: response,
                        dropOffOrigin: action?.payload?.dropOffOrigin as Origin,
                      },
                    })
                  );
                }

                if (
                  this.helper.hasSlots(claim, response) ||
                  this.helper.selfSendBooking(claim)
                ) {
                  return of(
                    claimActions.PutServiceOptionSuccess({
                      payload: {
                        response,
                      },
                    })
                  );
                }

                if (manualReferral) {
                  return of(
                    claimActions.ManualBooking({
                      payload: {
                        noBookingDatesFound: true,
                        serviceOptionID: manualReferral.ServiceOptionID,
                      },
                    })
                  );
                }

                if (this.helper.isCallbackBooking(claim, response)) {
                  return of(
                    claimActions.PutServiceOptionSuccess({
                      payload: { response, isServiceProviderCallback: true },
                    })
                  );
                }

                return of(
                  claimActions.Error({
                    payload: new Error(ErrorMessages.NoEngineerAvailable),
                  })
                );
              }),
              catchError((err) => {
                const attemptCount: number = action?.payload?.attemptCount || 0;
                const manualReferral = this.helper.getManualReferralFallback(
                  claim?.serviceOptions?.ServiceOptions as ServiceOption[]
                );

                if (
                  this.helper.canRetryRequest(
                    attemptCount,
                    err?.error?.StatusCode || err?.errorDetail?.StatusCode
                  )
                ) {
                  return of(
                    claimActions.RetryPutServiceOption({
                      payload: {
                        attemptCount: attemptCount + 1,
                      },
                    })
                  );
                }

                if (this.helper.isWeekEmpty(err?.errorDetail)) {
                  return of(
                    claimActions.GetBookingFollowingWeekSlots({
                      payload: err?.errorDetail,
                    })
                  );
                }

                if (this.helper.hasSlots(claim)) {
                  return of(
                    claimActions.GetBookingExtraSlotsFailed({
                      payload: this.helper.getManualReferral(err?.errorDetail),
                    })
                  );
                }

                if (manualReferral) {
                  return of(
                    claimActions.ManualBooking({
                      payload: {
                        noBookingDatesFound: true,
                        serviceOptionID: manualReferral.ServiceOptionID,
                      },
                    })
                  );
                }

                return of(claimActions.Error({ payload: err }));
              })
            );
        }
      )
    )
  );

  dropOffServiceOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.DropOffPutServiceOption),
      switchMap(
        (action: {
          type: string;
          payload: {
            response: Api.PutServiceOptionResponse;
            dropOffOrigin: Api.GetServiceOptionRequest;
          };
        }) => {
          return Promise.all([
            this.maps.getData(
              action.payload.response.ServiceProviders as Api.ServiceProvider[]
            ),
            this.maps.getOrigin(action.payload.dropOffOrigin),
          ])
            .then(([serviceProviders, origin]) =>
              this.maps.getDistanceMatrix(origin, serviceProviders)
            )
            .then(
              (result: {
                origin: Origin;
                serviceProviders: Api.ServiceProvider[];
              }) => {
                return claimActions.DropOffPutServiceOptionSuccess({
                  payload: {
                    response: {
                      ...action.payload.response,
                      ServiceProviders: [...result.serviceProviders],
                    },
                    dropOffOrigin: result.origin,
                  },
                });
              }
            );
        }
      ),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  manualBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.ManualBooking,
        claimActions.ClaimActionTypes.PayAndClaim
      ),
      switchMap((action) => {
        return zip(
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          of(action)
        );
      }) as OperatorFunction<
        unknown,
        [Claim, { type: string; payload: PayloadBooking }]
      >,
      switchMap(
        ([claim, action]: [
          Claim,
          { type: string; payload: PayloadBooking }
        ]) => {
          return this.claimService
            .putServiceOption({
              ClaimID: claim?.claimID as string,
              ServiceOptionID: action.payload.serviceOptionID as string,
              ServiceOptionRequiredFields: [],
              AvailabilityEndDate: '',
              AvailabilityStartDate: '',
            })
            .pipe(
              map(() => {
                return claimActions.PutRepairData({
                  payload: {
                    request: {
                      ClaimID: claim?.claimID as string,
                      PlanNo: claim.reflect?.planNumber as string,
                      ExcessPaid: false,
                      ExtraInformation: '',
                      AppointmentDate: '',
                      AppointmentSlotIdentifier: '',
                    },
                    customerDetails: claim.customer,
                  },
                });
              })
            );
        }
      ),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  claimRebook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.ClaimRebook),
      switchMap(
        () =>
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>
      ),
      switchMap((claim: Claim) => {
        return this.claimService.rebookServiceOption().pipe(
          flatMap((response: Api.PutServiceOptionResponse) => {
            const bookingResponse = this.helper.formatLegacyResponse(response);

            if (this.helper.hasSlots(claim, bookingResponse)) {
              return of(
                claimActions.RebookClaimDataSuccess({
                  payload: bookingResponse,
                })
              );
            }

            return of(
              claimActions.Error({
                payload: new Error(ErrorMessages.NoEngineerAvailable),
              })
            );
          })
        );
      }),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  bookingDatesLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.PutServiceOptionSuccess,
        claimActions.ClaimActionTypes.DropOffPutServiceOptionSuccess
      ),
      switchMap(() => {
        return this.routing.isBookingRoute() ? of(claimActions.Loaded()) : [];
      })
    )
  );

  loadCardPaymentRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.LoadCardPaymentRedirect),
      switchMap(
        ({ payload }: { payload: { request: CardPaymentRedirectRequest } }) => {
          return this.claimService
            .postCardPaymentRedirect(payload.request)
            .pipe(
              flatMap((response) => {
                return of(
                  claimActions.LoadCardPaymentRedirectSuccess({
                    payload: response as CardPaymentRedirectResponse,
                  })
                );
              })
            );
        }
      ),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  completePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.CompletePayment),
      switchMap(({ payload }: { payload: Api.PaymentRequest }) => {
        return this.claimService.completePayment(payload).pipe(
          withLatestFrom(this.claimFacade.activeClaim$),
          flatMap(([response, claim]) => {
            this.claimFacade.selectServiceOption(
              claim.serviceOption as ServiceOption
            );
            return of(claimActions.CompletePaymentSuccess());
          })
        );
      }),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  setPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.SetPassword),
      switchMap(({ payload }: { payload: Api.PasswordRequest }) => {
        return this.claimService
          .setPassword(payload)
          .pipe(switchMap(() => of(claimActions.SetPasswordSuccess())));
      }),
      catchError((err) => of(claimActions.SetPasswordFail())),
      repeat()
    )
  );
}
