<h2 *ngIf="form?.value?.slotIdentifier">Confirm if you’d like to book:</h2>

<div class="notice">
  <div class="xs-12 flex notice__header">
    <svg class="svg-icon icon-calendar">
      <use xlink:href="#icon-calendar"></use>
    </svg>
    <div class="flex-column">
      <h5 class="typog-body-bold xs--block mr-4 mb-none">
        Engineer visit date
      </h5>
      <p class="xs--block">{{ day?.date | date : 'd MMMM YYYY' }}</p>
    </div>
  </div>
  <div
    class="xs-12 flex notice__description"
    [ngClass]="{ 'notice__description--active': form?.value?.slotIdentifier }"
  >
    <svg class="svg-icon icon-clock">
      <use xlink:href="#icon-clock"></use>
    </svg>
    <div class="flex-column" *ngIf="!form?.value?.slotIdentifier">
      <p class="xs--block typog-body-bold">What time would you like to book?</p>
    </div>
    <div class="flex-column" *ngIf="form?.value?.slotIdentifier">
      <h5 class="typog-body-bold xs--block mr-4 mb-none">Time slot</h5>
      <p class="xs--block">
        {{ slotSelected?.startTime }} - {{ slotSelected?.endTime }}
      </p>
    </div>
  </div>
</div>

<form *ngIf="form" [formGroup]="form" (change)="selectSlot()">
  <div *ngIf="form.value.slotIdentifier === null" [@fadeInAnimation]>
    <dg-fb-radiobutton
      *ngFor="let group of formData?.groups"
      [elementsPerPage]="group.radios?.elementsPerPage"
      [group]="form"
      [align]="'justify--left'"
      [classes]="'dg-radios ' + group.radios?.classes"
      [idPrefix]="group.radios?.control"
      [control]="group.radios?.control"
      [items]="group.radios?.radioGroup"
      [validators]="group.radios?.validators"
    ></dg-fb-radiobutton>
  </div>
</form>

<div
  class="slot-confirm"
  *ngIf="form?.value?.slotIdentifier"
  [@fadeInAnimation]
>
  <p class="link" (click)="clearSlot()">Change time slot</p>

  <dgx-components-button
    type="submit"
    variant="primary"
    iconName="arrow-right"
    data-automation="'ConfirmAD'"
    (click)="confirm()"
  >
    Book this time slot
  </dgx-components-button>
</div>
