<ng-container>
  <div
    *ngIf="group && control"
    class="container-absolute"
    (mousedown)="lock($event)"
    (touchstart)="lock($event)"
    (mouseup)="move($event)"
    (touchend)="move($event)"
  >
    <div #slider [class]="classes" [formGroup]="group">
      <ng-container *ngFor="let slide of slides; let i = index">
        <div class="slide" id="s{{ i }}">
          <div class="grid-row {{ align }}">
            <ng-container *ngFor="let data of slide; let j = index">
              <div [class]="data.classes">
                <div
                  class="dg-radios__item"
                  [ngClass]="{
                    error: !group.get(control)?.valid && showValidation,
                    active: group.get(control)?.value === data.value
                  }"
                >
                  <input
                    class="dg-radios__input"
                    id="{{ control + data.value }}"
                    [formControlName]="control"
                    [attr.data-automation]="control + data.value"
                    type="radio"
                    [value]="data.value"
                  />
                  <label
                    class="dg-label dg-radios__label"
                    for="{{ control + data.value }}"
                  >
                    <svg [class]="'icon ' + data.svgClasses" *ngIf="data.icon">
                      <use [attr.xlink:href]="'#' + 'icon-' + data.icon"></use>
                    </svg>

                    <br class="wrap-text" *ngIf="data.icon" />
                    <span [innerHTML]="data.text"></span>
                  </label>
                  <ul *ngIf="data.list" [class]="'dg-list' + data.list.classes">
                    <li *ngFor="let item of data.list.text">{{ item }}</li>
                  </ul>
                  <dg-fb-hint *ngIf="data.hint" [hint]="data.hint"></dg-fb-hint>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <ul
      class="pagination"
      [ngClass]="{ 'is-hidden': slides.length <= 1 }"
      #page
    >
      <li *ngFor="let slide of slides; let i = index" id="{{ i }}"></li>
    </ul>
    <div class="xs-12">
      <div class="col">
        <!-- conditional radioHint -->
        <dg-fb-radiobutton-hint *ngIf="radioHint">
          {{ radioHint }}
        </dg-fb-radiobutton-hint>
        <ng-container *ngIf="validators && showValidation">
          <dg-fb-validation-error-formcontrol
            [group]="group"
            [validators]="validators"
            [control]="control"
          >
          </dg-fb-validation-error-formcontrol>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
