import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderLinks } from '@domgen/dgx-components';
import { ClaimsAppComponentService } from './claims-app-component.service';

@Component({
  selector: 'claims-app-root',
  templateUrl: './claims-app.component.html',
  styleUrls: ['./claims-app.component.scss'],
})
export class ClaimsAppComponent {
  links$ = this.service.links$ as Observable<HeaderLinks>;
  loadingState$: Observable<{
    isLoading: boolean;
    message: string | null;
  }> = this.service.loadingState$;

  user$ = this.service.user$;

  constructor(public readonly service: ClaimsAppComponentService) {}
}
