import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomWindow } from '../interfaces/custom-window.interface';

declare let window: CustomWindow;
export interface Config {
  production: boolean;
  api?: string;
  myAccountApi?: string;
  dgHomePage?: string;
  plansHomePage?: string;
  salesAppHost?: string;
  personalDetailsPage?: string;
  cookieProSrc?: string;
  cookieProDomainScript?: string;
  commonPersonServiceUrl?: string;
  requestSource: string;
  requestAction: string;
  channelCode: string;
  countryCode: string;
  common?: string;
  myAccountIdentity?: string;
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private apiVersion = 'v1';
  public API: string | null = null;
  public myAccountApi: string | null = null;
  public commonESB: string | undefined = undefined;
  public commonPersonServiceUrl: string | null = null;
  public domain: string | null = null;
  public myAccountIdentity: string | undefined = undefined;
  public config: Config | null = null;

  constructor(private http: HttpClient) {}

  loadConfigurationData(environment?: Config): Promise<Config> {
    return new Promise<Config>((resolve) => {
      this.http
        .get<Config>('./config.json')
        .toPromise()
        .then((config) => this.initializeCookiePro(config as Config))
        .then((config: Config) => {
          this.config = { ...environment, ...config };
          this.API = `${config?.api ? config.api : environment?.api}/${
            this.apiVersion
          }`;

          this.commonESB = config.common;

          this.domain = window.location.origin;

          this.commonPersonServiceUrl = `${config?.commonPersonServiceUrl}/${this.apiVersion}`;
          this.myAccountIdentity = `${config?.myAccountIdentity}`;

          this.myAccountApi = `${
            config?.myAccountApi
              ? config.myAccountApi
              : environment?.myAccountApi
          }`;
          this.fixZoomIssue();
          resolve(config);
        });
    });
  }

  initializeCookiePro(config: Config) {
    if (!config?.cookieProSrc && !config?.cookieProDomainScript) {
      return config;
    }

    const scriptElement = document.createElement('script');
    const head = document.getElementsByTagName('head')[0];

    scriptElement.src = config.cookieProSrc as string;
    scriptElement.setAttribute(
      'data-domain-script',
      config?.cookieProDomainScript as string
    );

    // Write CookiePro script to top of head
    head.insertBefore(scriptElement, head.childNodes[0]);

    return config;
  }

  fixZoomIssue() {
    const addMaximumScaleToMetaViewport = () => {
      const el = document.querySelector('meta[name=viewport]');

      if (el !== null) {
        let content = el.getAttribute('content') as string;
        const re = /maximum\-scale=[0-9\.]+/g;

        if (re?.test(content)) {
          content = content.replace(re, 'maximum-scale=1.0');
        } else {
          content = [content, 'maximum-scale=1.0'].join(', ');
        }

        el.setAttribute('content', content);
      }
    };

    const checkIsIOS = () =>
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (checkIsIOS()) {
      addMaximumScaleToMetaViewport();
    }
  }
}
