import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { format } from 'date-fns';
import { fadeInAnimation } from '../../../animations/animations';
import { CalendarDay, CalendarSlot } from '../../../interfaces';
import {
  FormDataSchema,
  RadioGroupDefinition,
} from '../../../modules/formbuilder/interfaces/form-controls-definition.interface';
import { FormsService } from '../../../modules/formbuilder/services/forms.service';
import set from 'date-fns/set';

@Component({
  selector: 'dg-booking-calendar-day-specific-slot',
  templateUrl: './booking-calendar-day-specific-slot.component.html',
  styleUrls: ['./booking-calendar-day-specific-slot.component.scss'],
  animations: [fadeInAnimation],
})
export class BookingCalendarDaySpecificSlotComponent implements OnInit {
  @Input() day?: CalendarDay;
  @Output() selected: EventEmitter<CalendarSlot> = new EventEmitter();

  formData?: FormDataSchema['meta'];
  form?: UntypedFormGroup;
  slotSelected: CalendarSlot | undefined = undefined;
  controlName = 'slotIdentifier';
  elementsPerPage = 4;

  constructor(private formsService: FormsService) {}

  ngOnInit(): void {
    this.formData = {
      groups: [
        {
          radios: {
            control: this.controlName,
            elementsPerPage: 4,
            classes: `d-flex radio--button`,
            validators: [],
            radioGroup: this.day?.slots?.map(
              (type: CalendarSlot, index: number) => ({
                value: type.identifier,
                text: `${this.formatTime(type.startTime)} - ${this.formatTime(
                  type.endTime
                )}`,
                classes: `${
                  (this.day?.slots?.length as number) > this.elementsPerPage
                    ? 'xs-6'
                    : 'xs-12 md-6'
                }`,
                hiddenAccessibilityText: '',
              })
            ) as RadioGroupDefinition[],
          },
        },
      ],
    };

    this.form = new UntypedFormGroup(
      this.formsService.defineformControls(this.formData.groups, {})
    );
  }

  private formatTime(time: string): string {
    const amPm = time >= '12:00' ? 'pm' : 'am';
    return `${format(this.getDate(time), 'h:mm').replace(/:00/, '')}${amPm}`;
  }

  private getDate(time: string) {
    const [hours, minutes] = time.split(':');
    return set(this.day?.date as Date, {
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: 0,
    });
  }

  selectSlot() {
    const slot = this.day?.slots?.find(
      (s, index) => s.identifier === this.form?.value?.slotIdentifier
    ) as CalendarSlot;

    this.slotSelected = {
      ...slot,
      startTime: this.formatTime(slot.startTime),
      endTime: this.formatTime(slot.endTime),
    };
  }

  confirm() {
    this.selected.emit(this.slotSelected);
  }

  clearSlot() {
    this.form?.controls[this.controlName].patchValue(null);
  }
}
